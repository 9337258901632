import { HeadFC, PageProps } from "gatsby"
import React from "react"

import { Box, Wrap, useDisclosure } from "@chakra-ui/react"
import { HeadSEO } from "../components/head/"
import { ComplainModal } from "../components/pages/contact/complain"
import { DoubtModal } from "../components/pages/contact/doubt"
import { Faq } from "../mocks/faq"
import "../styles/politicaeprivacidade.css"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

const PerguntasFrequentesPage: React.FC<PageProps> = () => {
  const [seeAll, setSeeAll] = React.useState(false)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenComplain,
    onClose: onCloseComplain,
    onOpen: onOpenComplain,
  } = useDisclosure()

  return (
    <body id="duvidas">
      <DoubtModal isOpen={isOpen} onClose={onClose} />
      <ComplainModal isOpen={isOpenComplain} onClose={onCloseComplain} />
      <div className="container mt-5 mb-5">
        <div className="row header">
          <div className="col">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="h3">Divulgação do Relatório de Transparência Salarial</span>
            </div>
            <div className="d-flex flex-column p-4">              
              <p className="text-subtitle-relatorio">
                De acordo com nosso compromisso com a promoção de um ambiente de trabalho inclusivo e equitativo e atendendo à Lei nº 14.611 de 2023, que dispõe sobre a Igualdade Salarial e de Critérios Remuneratórios entre Mulheres e Homens, a Verde Campo divulga seu Relatório de Transparência Salarial.
              </p>
              <p className="text-subtitle-relatorio">
                Segundo a lei, a publicação do relatório deve ser feita de forma clara e acessível, garantindo a ampla divulgação.
              </p>
              <p className="text-subtitle-relatorio">
                Clique <a href="https://admin.verdecampo.com.br/wp-content/uploads/2024/09/relatório-de-transparência.pdf" target="_blank" rel="noopener noreferrer">aqui</a> e acesse o relatório em PDF.
              </p>            
            </div>
          </div>
        </div>
       
      </div>
     
    </body>
  )
}

export default PerguntasFrequentesPage

export const Head: HeadFC = () =>
  HeadSEO(capitalizeFirstLetter(`Divulgação do Relatório de Transparência Salarial | Verde Campo`), "De acordo com nosso compromisso com a promoção de um ambiente de trabalho inclusivo e equitativo e atendendo à Lei nº 14.611 de 2023", "")
